import { authentication } from './authentication'
import { fetch } from '@tomra/client-side-http-client'

const requestConfig = {
  fetchNewToken: authentication.fetchNewToken,
  getToken: authentication.getToken,
}

const JSON_HEADERS = { 'Content-Type': 'application/json' }

export function authenticatedGet(url: string) {
  const options = {
    method: 'GET',
    headers: JSON_HEADERS,
  }

  return fetch(url, options, requestConfig)
}
