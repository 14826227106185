import React, { useState } from 'react'
import { ArrowDownIcon } from '../icons/ArrowDownIcon'
import { ArrowUpIcon } from '../icons/ArrowUpIcon'

export function useTableSorting<T>(
  columnValueExtractorMap: { [key: string]: (candidate: T) => string | number },
  defaultColumn?: string
) {
  const [[column, direction], setSorting] = useState<[string, 'ASC' | 'DESC']>([defaultColumn ?? '', 'DESC'])

  const sort = (array: T[]): T[] => {
    const extractor = columnValueExtractorMap[column]
    if (extractor) {
      return array.sort((a, b) => {
        const inverter = direction === 'DESC' ? 1 : -1
        return (extractor(a) > extractor(b) ? -1 : 1) * inverter
      })
    }
    return array
  }

  return {
    currentSort: {
      column,
      direction,
      setSorting,
    },
    sort,
  }
}

type Props = {
  name: string
  currentSort: {
    column: string
    direction: 'ASC' | 'DESC'
    setSorting: (value: [string, 'ASC' | 'DESC']) => void
  }
  alignRight?: boolean
}

export const TableHeaderSortable = ({ name, currentSort, alignRight }: Props) => {
  return (
    <th>
      <div className={`flex items-center ${alignRight ? 'justify-end' : ''}`}>
        <button
          className={`cursor-pointer font-bold ${alignRight ? 'text-right' : ''}`}
          onClick={() => {
            if (currentSort.column === name) {
              if (currentSort.direction === 'ASC') {
                currentSort.setSorting([name, 'DESC'])
              } else {
                currentSort.setSorting([name, 'ASC'])
              }
            } else {
              currentSort.setSorting([name, 'ASC'])
            }
          }}
        >
          {name}
        </button>
        {currentSort.column === name ? (
          currentSort.direction === 'ASC' ? (
            <ArrowDownIcon size="20" />
          ) : (
            <ArrowUpIcon size="20" />
          )
        ) : (
          <div style={{ height: 20, width: 20 }} />
        )}
      </div>
    </th>
  )
}
