import Logo from 'icons/Logo'
import { LogoutIcon } from 'icons/LogoutIcon'
import { FunctionComponent } from 'react'
import { Link } from 'wouter'
import { AppMenuMountDiv } from './AppMenuMountDiv'

type HeaderProps = {
  doLogout: () => void
  userToken: string
}

export const Header: FunctionComponent<HeaderProps> = ({ doLogout, userToken }) => {
  return (
    <div className="navbar">
      <div className="flex items-center gap-md">
        <Logo />
        <Link href="/">Bin Status Dashboard - QLD</Link>
      </div>

      <div className="flex gap-md">
        <AppMenuMountDiv userToken={userToken} />

        <button onClick={doLogout}>
          <LogoutIcon color="white" />
        </button>
      </div>
    </div>
  )
}
