import React, { useEffect, useState } from 'react'
import { Header } from './components/Header'
import { failure, loading, notAsked, RD, SRD, success } from 'srd'
import { BinStatusOverview } from './components/BinStatusOverview'
import { BinStatusResponse, ErrorResponse } from './datatypes'
import { fetchData, getUnknownError } from './lib/service'
import { HttpError } from '@tomra/client-side-http-client'

type Props = {
  doLogout: () => void
  userToken: string
}

export const App = ({ doLogout, userToken }: Props) => {
  const [rd, setRd] = useState<RD<ErrorResponse, BinStatusResponse>>(notAsked())

  useEffect(() => {
    setRd(loading())
    fetchData()
      .then((response) => {
        setRd(success(response))
      })
      .catch((err: HttpError) => {
        setRd(failure(err.body ? (err.body as ErrorResponse) : getUnknownError(err)))
      })
  }, [])

  return (
    <>
      <Header doLogout={doLogout} userToken={userToken} />
      <div className="card p-sm m-0 md:p-md md:m-md 2xl:mx-10 ">
        {SRD.match(
          {
            notAsked: () => <div />,
            loading: () => <div className="loadingSpinner text-xl m-md" />,
            failure: (err) => (
              <div className="alert alert-danger m-md text-lg">
                Failed to fetch data:
                <div className="text-sm">
                  Details:
                  <br />
                  {err.detail ? err.detail : err.toString()}
                </div>
              </div>
            ),
            success: (response: BinStatusResponse) => (
              <BinStatusOverview updatedAt={response.meta.updatedAt} data={response.data ?? []} />
            ),
          },
          rd
        )}
      </div>
    </>
  )
}

export default App
