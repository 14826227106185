import { BinStatusResponse, ErrorResponse, Location } from '../datatypes'
import { API_HOST } from './environment'
import { authenticatedGet } from './httpClient'
import { HttpError } from '@tomra/client-side-http-client'

export function fetchData(): Promise<BinStatusResponse> {
  const url = `${API_HOST}/v1/external/AU_QLD/statuses`

  return authenticatedGet(url)
    .run()
    .then((resp: { meta: { updatedAt: string }; data: Location[] }) => resp)
}

export function getUnknownError(ex: HttpError): ErrorResponse {
  return {
    errorId: 'unknown',
    status: 0,
    title: 'Unknown Error',
    type: 'unknown',
    detail: ex.message,
  }
}
