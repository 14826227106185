import { Location } from '../datatypes'
import { TableSortable } from './TableSortable'

type Props = {
  updatedAt: string | undefined
  data: Location[]
}

export const BinStatusOverview = ({ updatedAt, data }: Props) => {
  return (
    <>
      <div className="pb-sm text-sm">Last updated: {updatedAt ? new Date(updatedAt).toLocaleString() : ''}</div>
      <TableSortable locations={data} />
    </>
  )
}
