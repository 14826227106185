import tomraArrow from '../images/tomra-arrow.png'
type LogoProps = {
  size?: string
  color?: string
}

export const Logo = (props: LogoProps) => (
  <img height={props.size || '36'} width={props.size || '36'} src={tomraArrow} alt="Tomra logo" />
)

export default Logo
