import ReactDOM from 'react-dom'
import App from './App'
import { StrictMode } from 'react'
import { authentication } from './lib/authentication'
import { ENV_NAME, VERSION } from './lib/environment'

const mountNode = document.getElementById('root')
if (!mountNode) {
  throw new Error('Could not get #root element')
}

authentication.init().then(
  () => {
    ReactDOM.render(
      <StrictMode>
        <App doLogout={authentication.logout} userToken={authentication.getToken()} />
        <div className="mt-md ml-md">
          {VERSION} | {ENV_NAME}
        </div>
      </StrictMode>,
      document.getElementById('root')
    )
  },
  (err: any) => {
    if (err === 'Unauthenticated') {
      authentication.login()
    } else {
      authentication.logout()
    }
  }
)
