import { Location, LocationRow } from '../datatypes'
import { TableHeaderSortable, useTableSorting } from './TableHeaderSortable'
import { useState } from 'react'

type Props = {
  locations: Location[]
}

function toLocationRows(locations: Location[]): LocationRow[] {
  return (
    locations
      .flatMap((location) =>
        location.materialStatuses.map((materialStatus) => ({ ...location, ...materialStatus } as LocationRow))
      )
  )
}

export const TableSortable = ({ locations }: Props) => {
  const [filter, setFilter] = useState('')

  const sorting = useTableSorting<LocationRow>(
    {
      Name: (r) => r.name,
      'Store Reference': (r) => r.storeReference,
      Region: (r) => r.region,
      'Material Category': (r) => r.materialCategory,
      'RVM Count': (r) => r.rvmCount,
      'Offline Count': (r) => r.offlineCount,
      'Total Bins': (r) => r.totalBins,
      'Percent Full': (r) => r.percentFull,
      'CRP Size': (r) => r.collSize,
    },
    'Percent Full'
  )

  const rows = sorting.sort(toLocationRows(locations)).filter((location) => {
    const filterableTexts: string[] = [
      location.name,
      location.storeReference,
      location.region,
      location.materialCategory,
      location.collSize,
    ]
    return filterableTexts.some((text) => text.toLowerCase().includes(filter.toLowerCase().trim()))
  })

  return (
    <>
      <div className="pb-sm sm:w-1/2 md:w-1/3 lg:w-1/4">
        <label htmlFor="filter" className="label">
          <abbr title={'Searchable columns: Name, Store Reference, Region, Material Category, CRP Size'}>Search</abbr>
        </label>
        <input
          className="textfield h-auto"
          type="text"
          name="filter"
          value={filter}
          onChange={(event) => setFilter(event.target.value)}
        />
      </div>
      <table className="table">
        <thead>
          <tr>
            <TableHeaderSortable name={'Name'} currentSort={sorting.currentSort} />
            <TableHeaderSortable name={'Store Reference'} currentSort={sorting.currentSort} />
            <TableHeaderSortable name={'Region'} currentSort={sorting.currentSort} />
            <TableHeaderSortable name={'Material Category'} currentSort={sorting.currentSort} />
            <TableHeaderSortable name={'RVM Count'} currentSort={sorting.currentSort} alignRight={true} />
            <TableHeaderSortable name={'Offline Count'} currentSort={sorting.currentSort} alignRight={true} />
            <TableHeaderSortable name={'Total Bins'} currentSort={sorting.currentSort} alignRight={true} />
            <TableHeaderSortable name={'Percent Full'} currentSort={sorting.currentSort} alignRight={true} />
            {/*
            <TableHeaderSortable name={'Duration Full'} currentSort={sorting.currentSort} alignRight={true} />
            <TableHeaderSortable name={'Last Emptied'} currentSort={sorting.currentSort} alignRight={true} />
            <TableHeaderSortable name={'Last Full'} currentSort={sorting.currentSort} alignRight={true} />
*/}
            <TableHeaderSortable name={'CRP Size'} currentSort={sorting.currentSort} />
            {/*<TableHeaderSortable name={'Scale Size'} currentSort={sorting.currentSort} alignRight={true} />*/}
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => (
            <tr key={row.storeReference + row.materialCategory} className={row.percentFull >= 100 ? 'bg-pink' : ''}>
              <td>{row.name}</td>
              <td>{row.storeReference}</td>
              <td>{row.region}</td>
              <td>{row.materialCategory}</td>
              <td className="text-right">{row.rvmCount}</td>
              <td className="text-right">{row.offlineCount}</td>
              <td className="text-right">{row.totalBins}</td>
              <td className="text-right">{`${row.percentFull.toFixed(2)} %`} </td>
              {/*
              <td className="text-right">{row.durationFull}</td>
              <td className="text-right">{row.lastEmptiedAt}</td>
              <td className="text-right">{row.lastFullAt}</td>
*/}
              <td>{row.collSize}</td>
              {/*<td className="text-right">{row.scaleSize}</td>*/}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}
